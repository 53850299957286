.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.loading-wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #3498db);
  margin: 10px auto;
  animation: wave 3s ease-in-out infinite;
}

@keyframes wave {
  0% { transform: scale(0); }
  50% { transform: scale(1); }
  100% { transform: scale(0); }
}
