.invite-code-input-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-code-input-lightbox {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.invite-code-logo {
  max-width: 200px;
  margin-bottom: 1rem;
}

.invite-code-input-lightbox h2 {
  margin-bottom: 1rem;
}

.invite-code-input-lightbox form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invite-code-input-lightbox input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.invite-code-input-lightbox button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.invite-code-input-lightbox button:hover {
  background-color: #0056b3;
}
