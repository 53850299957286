.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.logo-container {
  margin-right: 20px;
}

.company-logo {
  height: 40px;
  width: auto;
}

.app-title {
  display: flex;
  flex-direction: column;
}

.app-title h1 {
  margin: 0;
  font-size: 24px;
  color: #003895;
}

.slogan {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
}

.main-content {
  display: flex;
  height: calc(100vh - 60px); /* Adjust based on your header height */
}

.left-panel, .right-panel {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.pdf-controls {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.pdf-controls button {
  margin: 0 5px;
}

.pdf-container {
  height: calc(100% - 40px); /* Adjust based on your PDF controls height */
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.status {
  margin: 10px 0;
}

button {
  margin-top: 10px;
}

.react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 10px;
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.log-display {
  height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
}

.log-display pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.editor-container {
  flex-grow: 1;
  min-height: 300px;
}

.extracted-data-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-header h2 {
  margin: 0;
}

.form-header-buttons {
  display: flex;
  gap: 10px;
}

.back-button,
.confirm-process-button {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.confirm-process-button {
  background-color: #003895;
}

.confirm-process-button:hover {
  background-color: #002d75;
}

.back-button {
  background-color: #4CAF50;
}

.back-button:hover {
  background-color: #45a049;
}

.extracted-data-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  align-items: flex-start;
  position: relative;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-height: 45px;
  margin-bottom: 20px;
}

.form-group.full-width {
  flex: 0 0 100%;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

button {
  padding: 10px 15px;
  background-color: #003895;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.form-buttons {
  margin-bottom: 20px;
}

.back-button {
  background-color: #000801;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.back-button:hover {
  background-color: #45a049;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.custom-file-upload {
  border: 2px solid #003895;
  display: inline-block;
  padding: 20px;
  cursor: pointer;
  background-color: #ffffff;
  color: #003895;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #003895;
  color: #ffffff;
}

.file-name {
  margin-top: 10px;
  font-style: italic;
}

.job-queue {
  margin-top: 20px;
  font-size: 12px;
}

.job-queue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.job-queue-header h2 {
  margin: 0;
}

.pagination {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.pagination button {
  background-color: #003895;
  color: white;
  border: none;
  padding: 3px 6px;
  margin: 0 3px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 12px;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 5px;
}

.job-queue table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.job-queue th, .job-queue td {
  padding: 6px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-queue th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  font-size: 11px;
}

.job-queue-table .filename-column {
  width: 30%; /* Reduced from 50% */
}

.job-queue-table .doc-type-column,
.job-queue-table .shipment-type-column,
.job-queue-table .shipment-direction-column {
  width: 10%;
}

.job-queue-table .status-column {
  width: 15%; /* Reduced from 20% */
}

.job-queue-table .last-updated-column {
  width: 10%;
}

.job-queue-table .action-column {
  width: 10%;
}

.job-queue-table .view-column {
  width: 5%;
  text-align: left;
}

.job-queue-table .view-column a {
  color: #003895;
  text-decoration: none;
  font-size: 12px;
}

.job-queue-table .view-column a:hover {
  text-decoration: underline;
}

.job-queue-table th.last-updated-column {
  font-size: 11px;
}

.job-queue-table td.last-updated-column {
  font-size: 8px;
}

.job-queue button {
  padding: 2px 6px;
  font-size: 11px;
  margin: 0;
  white-space: nowrap;
}

.job-queue button:hover {
  background-color: #45a049;
}

.job-queue button.retry-button {
  background-color: #FF8C00;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.job-queue button.retry-button:hover {
  background-color: #E67300;
}

.job-queue button.retry-button svg {
  font-size: 14px;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.processing-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #3498db;
  border-radius: 50%;
  animation: pulse 1s infinite ease-in-out;
  margin-right: 5px;
}

.processing-status {
  display: flex;
  align-items: center;
  color: #3498db;
}

.ready-for-review-status {
  display: flex;
  align-items: center;
  color: #4CAF50;
}

.submitted-status {
  display: flex;
  align-items: center;
  color: #2196F3;
}

.error-status {
  display: flex;
  align-items: center;
  color: #f44336;
}

.queued-status {
  display: flex;
  align-items: center;
  color: #FFA500;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.loading-wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #3498db);
  margin: 10px auto;
  animation: wave 1s ease-in-out infinite;
}

@keyframes wave {
  0% { transform: scale(0); }
  50% { transform: scale(1); }
  100% { transform: scale(0); }
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-info span {
  margin-right: 10px;
  font-size: 14px;
}

.logout-button {
  background-color: transparent;
  border: 1px solid #003895;
  color: #003895;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: #003895;
  color: white;
}

.logout-button:hover svg {
  color: white;
}

.logout-button svg {
  margin-right: 5px;
  color: #003895;
  transition: all 0.3s ease;
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  z-index: 1000;
  animation: fadeIn 0.5s, fadeOut 0.5s 9.5s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.loading-indicator {
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
  display: block;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: none;
}

.extracted-indicator {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 0.7em;
  color: #666;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 4px;
  border-radius: 3px;
  pointer-events: none;
}

.no-match-indicator {
  position: absolute;
  bottom: -15px;
  left: 0;
  height: 15px;
  line-height: 15px;
  font-size: 0.8em;
  color: #ff6b6b;
  pointer-events: none;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0.6;
  filter: invert(0.8);
}

.custom-select {
  position: relative;
  width: 100%;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.options-list li {
  padding: 8px;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f0f0f0;
}

.no-options {
  color: #666;
  font-style: italic;
}

.tooltip-icon {
  font-size: 0.8em;
  color: #666;
  margin-left: 5px;
  cursor: help;
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: fixed;
  bottom: auto;
  left: 25%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;
  text-align: center;
  z-index: 1000;
  top: 100px;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.shipment-type-selection,
.shipment-direction-selection,
.document-type-selection {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shipment-type-selection h2,
.shipment-direction-selection h2,
.document-type-selection h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.shipment-type-grid,
.shipment-direction-grid,
.document-type-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.shipment-type-button,
.shipment-direction-button,
.document-type-button {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.shipment-type-button:hover,
.shipment-direction-button:hover,
.document-type-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shipment-type-button:active,
.shipment-direction-button:active,
.document-type-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shipment-type-button.coming-soon,
.document-type-button.coming-soon {
  background-color: #95a5a6;
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.7); /* Slightly transparent text */
}

.coming-soon-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* More transparent background */
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;
}

.shipment-type-button.coming-soon:hover,
.document-type-button.coming-soon:hover {
  background-color: #95a5a6;
  transform: none;
  box-shadow: none;
}

.loading-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  line-height: 5px;
  font-size: 0.8em;
  color: #666;
  pointer-events: none;
}

.loading-indicator {
  display: block;
  height: 20px;
}

.no-match-indicator {
  position: absolute;
  bottom: -15px;
  left: 0;
  height: 15px;
  line-height: 15px;
  font-size: 0.8em;
  color: #ff6b6b;
  pointer-events: none;
}

input.no-match {
  border-color: #ff6b6b;
}

.match-indicator {
  position: absolute;
  bottom: -15px;
  left: 0;
  height: 15px;
  line-height: 15px;
  font-size: 0.8em;
  pointer-events: none;
}

.match-indicator.no-match {
  color: #ff6b6b;
}

.match-indicator.has-match {
  color: #4CAF50;
}

input.has-match {
  border-color: #4CAF50;
  border-width: 2px;
}

input.no-match {
  border-color: #ff6b6b;
  border-width: 2px;
}

.error-container {
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.error-details {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 14px;
}

.error-details pre {
  white-space: pre-wrap;
  word-break: break-all;
}

.error-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.retry-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.retry-button:hover {
  background-color: #0056b3;
}